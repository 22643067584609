import Plyr from 'plyr';


const video = {
    elements: {
        video: '.js-video',
    },
    settings: {
    },
    init() {
        const self = this;
        const player = new Plyr(self.elements.video, {
        });

    }
}

export default video