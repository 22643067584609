import ScrollBooster from 'scrollbooster';

const timeline = {
    timelineSb: null,
    navSb: null,
    elements: {
        timeline: '.js-timeline',
        nav: '.js-timeline-nav',
        navLink: '.js-timeline-nav-link',
    },
    init: function() {
        const self = this;
        const $timeline = document.querySelector(self.elements.timeline);
        const $nav = document.querySelector(self.elements.nav);

        if ($timeline) {
            self.timelineSb = new ScrollBooster({
                viewport: $timeline,
                scrollMode: 'native',
                direction: 'horizontal'

            })
        }

        if ($nav) {
            self.navSb = new ScrollBooster({
                viewport: $nav,
                scrollMode: 'native',
                direction: 'horizontal'

            })

            const $navLinks = $nav.querySelectorAll(self.elements.navLink);

            $navLinks.forEach($navLink => {
                $navLink.addEventListener('click', (e) => {
                    e.preventDefault();
                    const $target = document.querySelector($navLink.getAttribute('href'));

                    if ($target) {
                        self.timelineSb.scrollTo({
                            x: $target.offsetLeft,
                            y: 0,
                        })
                    }
                })
            })
        }


    },
}

export default timeline