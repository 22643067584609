import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';


const gallery = {
    elements: {
        gallery: '.js-gallery',
    },
    init() {
        const self = this;
        const $gallery = document.querySelector(self.elements.gallery);

        if ($gallery) {



            const lightbox = GLightbox({
                selector: self.elements.gallery,
                touchNavigation: true,
                loop: true,
                autoplayVideos: true,
                plyr: {
                config: {
                    youtube: {
                        noCookie: true,      
                        rel: 0,
                        showinfo: 0,
                    },
                    vimeo: {
                        dnt: true,
                        byline: false,
                        portrait: false,
                        title: false,
                        speed: true,
                        transparent: false
                    },
                }
                }
            });
        }
    },
    reset() {
        const self = this;

        self.init();
    }
}

export default gallery