const htmxEvents = {
    elements: {
        back: '.js-htmx-back',
    },
    settings: {
    },
    init() {
        const self = this;
        const $back = document.querySelector(self.elements.back);

        if ($back) {
            $back.addEventListener("click", self.back, { once: true });
        }
    },
    reset() {
        const self = this;
        const $back = document.querySelector(self.elements.back);

        if ($back) {
            $back.removeEventListener("click", self.back);
        }

        self.init();
    },
    back(e) {
        e.preventDefault();
        window.history.back();
    }
}

export default htmxEvents