import '@splidejs/splide/css/core';
import Splide from '@splidejs/splide';

import { isLg } from './utils'

const slider = {
    classnames: {
        slider: '.js-slider',
    },
    settings: {
        delay: 2000,
    },
    instances: {},
    timeout: {},
    mouseOver: false,
    init() {
        const self = this;
        const $sliders = document.querySelectorAll(self.classnames.slider);

        $sliders.forEach(($slider, index) => {
            /*
            let thisSlider = null;

            const autoplay = $slider.dataset.autoplay ? $slider.dataset.autoplay : false;

            thisSlider = new KeenSlider($slider, {
                loop: true,
            }, [
                (thisSlider) => {

                    self.instances[index] = thisSlider;
                    if (autoplay) self.autoplay.init(thisSlider, index)
                }
            ]);
            */

            new Splide($slider, {
                arrows: false,
                type: 'fade',
                speed: 0,
            }).mount();
        });
        // self.cursor()
    },
    reset() {
        const self = this;
        Object.keys(self.instances).forEach(key => {
            self.instances[key].destroy();
        });
        self.instances = {};

        self.init();
    },

    /*
    autoplay: {
        init(thisSlider, index) {
            const self = slider;

            thisSlider.on("created", () => {

                thisSlider.container.addEventListener("mouseover", () => {
                    self.mouseOver = true
                    self.autoplay.clearNextTimeout(index)
                })
                thisSlider.container.addEventListener("mouseout", () => {
                    self.mouseOver = false
                    self.autoplay.nextTimeout(index)
                })
                self.autoplay.nextTimeout(index)
            })
            thisSlider.on("dragStarted", () => { self.autoplay.clearNextTimeout(index) })
            thisSlider.on("animationEnded", () => { self.autoplay.nextTimeout(index) })
            thisSlider.on("updated", () => { self.autoplay.nextTimeout(index) })
        },
        clearNextTimeout(index) {
            const self = slider;
                    
            clearTimeout(self.timeout[index])
        },
        nextTimeout(index) {
            const self = slider;
            
            clearTimeout(self.timeout[index])

            if (self.mouseOver) return

            self.timeout[index] = setTimeout(() => {
                self.instances[index].next()
            }, self.settings.delay)
        }
    },

    cursor() {

        const self = this;
        
        const $sliders = document.querySelectorAll('.js-slider');

        const $cursor = document.querySelector('.js-cursor');
        if (!$cursor) return;
        console.log($cursor)

        document.addEventListener('mousemove', function(e){

            if (!isLg()) return;

            var x = e.clientX;
            var y = e.clientY;
            $cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
        });

        if ($sliders.length > 0) {
            $sliders.forEach(function($slider) {
                
                const $left = $slider.parentNode.querySelector('.js-slider-prev');
                const $right = $slider.parentNode.querySelector('.js-slider-next');
                
                $slider.addEventListener('mousemove', function(e) {

                    if (!isLg()) return;
                    

                    $cursor.classList.remove('is-prev');
                    $cursor.classList.remove('is-next');

                    $cursor.classList.add('is-active');
                    document.body.style.setProperty('cursor', 'none', 'important');
                    $slider.classList.add('is-hover');

                    e.preventDefault();


                    const midpoint = $slider.getBoundingClientRect().left + ($slider.getBoundingClientRect().width / 2);

                    if (e.clientX < midpoint && $left) {
                        $cursor.classList.add('is-prev');
                    }

                    else if ($right) {
                        $cursor.classList.add('is-next');
                    }
                });

                $slider.addEventListener('mouseleave', function(e) {
                    if (!isLg()) return;
                    
                    e.preventDefault();
                    $cursor.classList.remove('is-active');
                    $cursor.classList.remove('is-prev');
                    $cursor.classList.remove('is-next');
                    document.body.style.cursor = 'auto';
                    $slider.classList.remove('is-hover');
                });

                $slider.addEventListener('click', function(e) {
                    if (!isLg()) return;

                    
                    var midpoint = $slider.getBoundingClientRect().left + ($slider.getBoundingClientRect().width / 2);

                    if (e.clientX < midpoint && $left) {
                        console.log(self.instances)
                        self.instances[0].prev();
                    } else if ($right) {
                        
                        console.log(self.instances[0])
                        self.instances[0].next();
                    }
                    
                });
            });
        
        }
    }
    */
}

export default slider