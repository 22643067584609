//
import * as L from "leaflet";
import 'leaflet.markercluster';

import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';



const map = {
    map: null,
    markers: [],
    currentMarker: null,
    settings: {
        center: [38.0216, -7.4780],
        zoom: 15,
        markerSize: 1.25,
        minClusterSize: 2.5,
        maxClusterSize: 7,
    },
    elements: {
        map: '.js-map',
        link: '.js-map-link'
    },
    init() {
        const self = this;
        const $map = document.querySelector(self.elements.map);

        if ($map) {

            self.map = L.map($map, {
                center: L.latLng(self.settings.center),
                zoom: self.settings.zoom,
            });

            L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(self.map);

            self.addMarkers();

        }
    },
    addMarkers() {
        const self = this;
        const $links = document.querySelectorAll(self.elements.link);

        const markerCluster = new L.MarkerClusterGroup({
            iconCreateFunction: function (cluster) {
                const markers = cluster.getAllChildMarkers();
                let size = markers.length;
                size = size > self.settings.maxClusterSize ? self.settings.maxClusterSize : size;
                size = size < self.settings.minClusterSize ? self.settings.minClusterSize : size;

                const html = '<div class="map-cluster">' + markers.length + '</div>';
                return L.divIcon({ html: html, className: 'mycluster', iconSize: L.point(size * 10, size * 10) });
            },
            spiderfyOnMaxZoom: false,
            showCoverageOnHover: false,
            zoomToBoundsOnClick: true
        });


        if ($links) {
            $links.forEach($link => {
                const data = $link.getAttribute('data-map');
                const title = $link.getAttribute('data-title');

                if (data) {
                    console.log(data);
                    const parsedData = JSON.parse(data);

                    if (parsedData.lat === null || parsedData.lng === null) {
                        return;
                    }

                    const lat = parsedData.lat;
                    const lng = parsedData.lng;

                    const customIcon = L.divIcon({
                        className: 'custom-icon',
                        iconSize: [self.settings.markerSize * 16, self.settings.markerSize * 16], // Set the size of the icon (4rem x 4rem)
                        html: '<div class="map-marker"></div>'
                    });

                    const marker = L.marker([lat, lng], { icon: customIcon })
                        .addTo(markerCluster)
                        .bindPopup(title)
                        .on('click', function(e) {
                            console.log(e);

                            $link.click();

                            self.currentMarker = self.slugifyCoords(lat, lng);
                        });


                    self.markers.push(marker);
                }

                $link.addEventListener('click', (e) => {
                    e.preventDefault();
                    self.moveMap(data, 17);
                });

                if ($link.classList.contains('is-active')) {
                    self.moveMap(data, 17);
                }
            });

            markerCluster.addTo(self.map);
        }
    },
    moveMap(data, zoom = self.settings.zoom) {
        const self = this;
        const parsedData = JSON.parse(data);
        const lat = parsedData.lat;
        const lng = parsedData.lng;

        self.map.setView([lat, lng], zoom);

        setTimeout(() => {
            self.markers.forEach(marker => {
                if (marker.getLatLng().lat === lat && marker.getLatLng().lng === lng) {


                    // marker.fireEvent('click');

                    marker.openPopup();
                }
            });
        }, 100);
    },
    slugifyCoords(lat, lng) {
        return lat + '-' + lng;
    },
    reset() {
        const self = this;

        self.init();
    }
}

export default map