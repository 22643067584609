const header = {
    elements: {
        header: '.js-header',
    },
    settings: {
        currentScroll: 0,
        isTop: true,
        offset: 0,
        scrollThreshold: 100,
        cumulativeScroll: 0,

    },
    init() {
        const self = this;

        self.toggleClasses();
        window.addEventListener('scroll', self.toggleClasses);

    },
    toggleClasses() {
        const self = header;
        const scrollY = window.scrollY;
        const $header = document.querySelector(self.elements.header);

        if (!$header) return;

        if (scrollY <= self.settings.offset) { // Check if top
            $header.classList.add('is-top');

        } else {
            const scrollDifference = scrollY - self.settings.currentScroll;
            self.settings.cumulativeScroll += Math.abs(scrollDifference);

            if (self.settings.cumulativeScroll >= self.settings.scrollThreshold) {
                $header.classList.remove('is-top'); // Remove top class

                if (scrollDifference > 0) { // User is scrolling down
                    $header.classList.remove('is-fixed');
                } else { // User is scrolling up
                    $header.classList.add('is-fixed');
                }

                self.settings.cumulativeScroll = 0; // Reset the cumulative scroll distance
            }
        }

        self.settings.currentScroll = scrollY;
    },

}

export default header